import axios from "axios";
import router from "./router/index";
localStorage.setItem("lang", 'ar');
var domain = "https://api2.taibapower.sa/api";
axios.defaults.baseURL = domain;
const http = axios.create({
  baseURL: axios.defaults.baseURL,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
    // "Accept-Language": "ar",
  },
});
http.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"]=localStorage.getItem('lang')??"ar"
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default http;
